<template>
  <div>
    <div  class="body-main">
      <div class="cart-header"><div class="cart-header-warp clearfix"><div class="cart-title left clearfix"><h1 class="left">支付中心</h1></div><div id="orderCartBanner"></div><div class="right"></div></div></div>
      <div class="cart-body">
        <div class="cart-body-title">
          <div class="clearfix">
            <div class="left trade-title"><span class="order">订单号：</span><span class="num">{{order.id}}</span></div>
            <div class="right"><a href="javascript:;" class="js-toggle-detai">收起</a></div>
          </div>
          <ul class="js-itemUl item-ul" style="height: auto">
            <li v-for="(v,i) in orderitemlist" :key="i" class="item clearfix" data-cid="474">
              <div class="item-left left clearfix">
                <a href="//coding.imooc.com/class/474.html" target="_blank"><img :src="v.imagesmall" width="160" height="90" class="left"/></a>
                <dl class="left"><a href="#//" target="_blank"><dt>{{v.coursename}}</dt></a></dl>
              </div>
              <div class="item-right right"><div class="price clearfix"><span class="price-text">实付金额：</span><em>￥</em><span>{{v.cusamountjs}}</span></div></div>
            </li>
          </ul>
        </div>
        <div class="pay-method">
          <h2 class="pay-method-title">支付方式</h2>
          <ul class="clearfix js-pay-method">
            <li @click="active='支付宝'" class="pay-way alipay " :class="{'active':active=='支付宝'}"><div class="bottomright"><div class="triangle"></div><i class="imv2-check"></i></div></li>
            <li @click="active='微信'" class="pay-way wxpay" :class="{'active':active=='微信'}"><div class="bottomright"><div class="triangle"></div><i class="imv2-check"></i></div></li>
            <li @click="active='京东'" class="pay-way jingdong can-jd-stage" :class="{'active':active=='京东'}" style="position: relative; overflow: visible">
              <span class="small-tips">可分期</span>
              <div class="bottomright"><div class="triangle"></div><i class="imv2-check"></i></div>
              <div id="jdPay" style="display: none; position: absolute; left: -10px; top: -12px; background: #f20d0d;  padding: 4px 8px; border-radius: 10px 10px 0px 10px; font-weight: bold; font-size: 12px; color: #ffffff;  text-align: center;line-height: 12px; ">白条支付立减5元</div>
            </li>
            <li @click="active='花呗'" :class="{'active':active=='花呗'}" class="pay-way huabei can-stage" >
              <span class="small-tips">可分期</span>
              <div class="bottomright"><div class="triangle"></div><i class="imv2-check"></i></div>
            </li>
            <li  @click="active='余额'" class="pay-way yue"  :class="{'active':active=='余额'}">
              <div class="leftImg"></div>
              <div class="rightInfo"><h2>我的余额</h2><p>￥<span>0.00</span></p></div>
              <div class="bottomright"><div class="triangle"></div><i class="imv2-check"></i></div>
            </li>
          </ul>
          <div class="huabei-detail-box clearfix">
            <div class="iterm l">
              <p class="pay-price">{{order.cusamountjs}}</p>
              <p class="handling-charge">花呗支付</p>
              <a href="javascript:void(0);" class="js-pay-submit huabei-pay" data-ordernum="2202051333235739" data-stage="-1">选择该支付方式</a>
            </div>
            <div class="line l"></div>
            <div class="iterm l">
              <p class="pay-price"><span>分期</span>¥101.95<i class="imv2-close"></i>3期</p>
              <p class="handling-charge">手续费 ¥2.29/期</p>
              <a href="javascript:void(0);" class="js-pay-submit huabei-pay" data-ordernum="2202051333235739" data-stage="3">选择该分期方式</a>
            </div>
            <div class="line l"></div>
            <div class="iterm l">
              <p class="pay-price"><span>分期</span>¥52.07<i class="imv2-close"></i>6期</p>
              <p class="handling-charge">手续费 ¥2.24/期</p>
              <a href="javascript:void(0);" class="js-pay-submit huabei-pay" data-ordernum="2202051333235739" data-stage="6">选择该分期方式</a>
            </div>
            <div class="line l"></div>
            <div class="iterm l">
              <p class="pay-price"><span>分期</span>¥26.77<i class="imv2-close"></i>12期</p>
              <p class="handling-charge">手续费 ¥1.86/期</p>
              <a href="javascript:void(0);" class="js-pay-submit huabei-pay" data-ordernum="2202051333235739" data-stage="12">选择该分期方式</a>
            </div>
            <a class="howto-use" href="//www.imooc.com/help/detail/126" target="_blank">为什么我不能使用花呗分期？</a>
          </div>
          <div class="jdpay-detail-box clearfix">
            <div class="iterm l">
              <p class="pay-price">¥299.00</p>
              <p class="handling-charge">京东支付</p>
              <a href="javascript:void(0);" class="js-pay-submit jd-pay" data-ordernum="2202051333235739" data-stage="-1">选择该支付方式</a>
            </div>
            <div class="line l"></div>
            <div class="iterm l">
              <p class="pay-price"><span>白条分期</span>¥101.75<i class="imv2-close"></i>3期</p>
              <p class="handling-charge">手续费 ¥2.09/期</p>
              <a href="javascript:void(0);" class="js-pay-submit jd-pay" data-ordernum="2202051333235739" data-stage="3">选择该分期方式</a>
            </div>
            <div class="line l"></div>
            <div class="iterm l">
              <p class="pay-price"><span>白条分期</span>¥51.92<i class="imv2-close"></i>6期</p>
              <p class="handling-charge">手续费 ¥2.09/期</p>
              <a href="javascript:void(0);" class="js-pay-submit jd-pay" data-ordernum="2202051333235739" data-stage="6">选择该分期方式</a>
            </div>
            <div class="line l"></div>
            <div class="iterm l">
              <p class="pay-price"><span>白条分期</span>¥27.00<i class="imv2-close"></i>12期</p>
              <p class="handling-charge">手续费 ¥2.09/期</p>
              <a href="javascript:void(0);" class="js-pay-submit jd-pay" data-ordernum="2202051333235739" data-stage="12">选择该分期方式</a>
            </div>
            <span class="jd-tip">以上为参考利率，请以实际交易为准。</span>
            <a class="howto-use" href="//www.imooc.com/help/detail/131" target="_blank">如何使用京东白条分期？</a>
          </div>
        </div>
        <div class="pay-summary clearfix huabei-active">
          <div class="warning">
            <i class="imv2-error_c"></i>
            <span class="js-countdown-time">已为你锁定课程席位，请在 <b>30分钟</b> 内完成支付即可</span>
          </div>
          <div class="summary">
            <div class="clearfix total"><span class="label">应付金额：</span><span class="price"><em>￥</em><span>{{order.cusamountjs}}</span></span></div>
            <div class="submit-warp clearfix">
              <input type="hidden" class="zg_hascoupon" value="0" />
              <span data-ordernum="2202051333235739" class="pay-summary-submit js-pay-submit" href="#/myorder">立即支付</span>
            </div>
            <div class="clearfix feedback-href"><a class="js-click-meiqia" href="javascript:void(0);" data-href="//">付款有问题，点我</a></div>
          </div>
        </div>
      </div>
      <input type="hidden" id="jsPrice" value="299.00" />
      <div class="question-btn"></div>
      <div class="js-qrcode-modal">
        <div class="qrcode-modal">
          <div class="qrcode-modal-header"><h3>学习咨询</h3><p>选课、学习遇到问题？<br />扫码添加指导老师 1V1 帮助你！</p><span class="close imv2-close js-close"></span></div>
          <p class="tip1">扫码加顾问老师 立即咨询</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "../../ulti/ajax";

export default {
  data(){
    return {
      codeID:'',
      orderitemlist:[],
      order:{},

      active:""
    }
  },
  computed:{
    zje(){
      let num = 0
      for(let p of this.cartList){
        num += parseInt(p.jine)
      }
      return num;
    }
  },

  async mounted() {
    await this.getdata();
  },

  created(){
    this.codeID = this.$route.query.codeid
    console.log(this.$route.query.codeid)
    document.title='p8课堂-我的订单';
  },

  methods: {

    getdata() {
      let id = this.$route.query.id;
      api.getOrder(id).then(response => {
        // console.log("222:"+JSON.stringify(response))
        if (response.data.code == 200) {
          this.orderitemlist = response.data.data.orderitemList;
          this.order = response.data.data;
          // console.log("222:"+JSON.stringify(this.data))
          //this.total = response.total;
        }
      });
    },





  }
};
</script>

<style>
@import url("../../assets/css/dd.css");
</style>
